import { walletOffers } from "../../js/path";

export default {
  data() {
    return {
      pageNo: null,
      submitted: false,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      tableData: [],
      products: [],
      currentPage: 1,
      activeTab: "all",
      params: "",
      key: 0,
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "title",
        },
        {
          key: "description",
        },
        {
          key: "action_type",
        },
        {
          key: "amount",
        },
        {
          key: "btn_txt",
          label: "Button Text",
        },
        {
          key: "is_active",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      form: {
        id: "",
        title: "",
        description: "",
        action_type: "",
        amount: "",
        btn_txt: "",
        is_active: false,
      },
      actionTypes: [],
    };
  },
  methods: {
    convertToPlainText(htmlString) {
      return htmlString.replace(/<[^>]+>/g, "");
    },

    searchFor() {
      if (this.filter.length > 1) this.fetchData();
      else if (this.filter.length == 0) this.fetchData();
    },

    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData();
        }
      } else if (this.filter.length == 0) this.fetchData();
    },

    filterPage() {
      if (this.pageNo.length > 0) {
        this.currentPage = this.pageNo;
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async fetchData() {
      this.$store.commit("loader/updateStatus", true);

      try {
        const url = walletOffers.getWalletOffers;
        const res = await this.getRequest(url);
        if (res) {
          this.tableData = res?.response;
        }
        this.$store.commit("loader/updateStatus", false);
      } catch (err) {
        this.$store.commit("toast/updateStatus"),
          {
            status: true,
            icon: "error",
            title: "somthing went wrong",
          };
        this.$store.commit("loader/updateStatus", false);
      }
    },

    async updateStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = `${walletOffers.updateOfferStatus}/${id}`;
        const data = await this.postRequest(url, {
          is_active: !this.tableData.data[index].is_active,
        });
        if (data.status) {
          this.tableData.data[index].is_active =
            !this.tableData.data[index].is_active;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async deleteTax(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        let url = `${walletOffers.getWalletOffers}/${id}`;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async submitData() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields",
          });
          return false;
        }
        this.$store.commit("loader/updateStatus", true);
        if (this.$route.name == "edit-wallet-offers") {
          let dataAppend = {};
          for (let key in this.form) {
            if (key == "id" && key !== "action_type" && key !== "is_active")
              continue;
            dataAppend[key] = this.form[key];
          }
          dataAppend["action_type"] = this.form.action_type.value;
          dataAppend["is_active"] = this.form.is_active ? 1 : 0;
          dataAppend["_method"] = "put";

          let url = `${walletOffers.getWalletOffers}/${this.form.id}`;
          const res = await this.postRequest(url, dataAppend);
          if (res?.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: res?.message,
            });
            this.$router.push("/wallet-offers");
          }
        } else {
          const dataAppend = new FormData();
          for (var key in this.form) {
            if (key !== "id" && key !== "action_type" && key !== "is_active") {
              dataAppend.append(key, this.form[key]);
            }
          }
          dataAppend.append("action_type", this.form.action_type.value);
          dataAppend.append("is_active", this.form.is_active ? 1 : 0);

          let url = walletOffers.getWalletOffers;
          const res = await this.postRequest(url, dataAppend);
          if (res?.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: res?.message,
            });
            this.$router.push("/wallet-offers");
          }
        }
        this.$store.commit("loader/updateStatus", true);
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
        this.$store.commit("loader/updateStatus", false);
      }
    },

    async fetchoffer(id) {
      try {
        this.$store.commit("loader/updateStatus", true);
        let url = `${walletOffers.getWalletOffers}/${id}`;

        const res = await this.getRequest(url);
        if (res.status) {
          const responseData = res.response;
          if (responseData.id != "" && responseData.id != null) {
            this.form.id = responseData.id;
          }
          if (responseData.title != "" && responseData.title != null) {
            this.form.title = responseData.title;
          }
          if (responseData.is_active != "" && responseData.is_active != null) {
            if (responseData.is_active == 1) {
              this.form.is_active = true;
            } else {
              this.form.is_active = false;
            }
          }
          if (
            responseData.description != "" &&
            responseData.description != null
          ) {
            this.form.description = responseData.description;
          }
          if (
            responseData.action_type != "" &&
            responseData.action_type != null
          ) {
            let data = {};
            data.value = responseData.action_type;
            this.form.action_type = data;
          }
          if (responseData.amount != "" && responseData.amount != null) {
            this.form.amount = responseData.amount;
          }
          if (responseData.btn_txt != "" && responseData.btn_txt != null) {
            this.form.btn_txt = responseData.btn_txt;
          }
        }
        this.$store.commit("loader/updateStatus", false);
      } catch (err) {
        this.$store.commit("toast/updateStatus"),
          {
            status: true,
            icon: "error",
            title: "Something went wrong",
          };
        console.log(err);
        this.$store.commit("loader/updateStatus", false);
      }
    },

    async fetchActionTypes() {
      try {
        let url = walletOffers.getActionTypes;
        const res = await this.getRequest(url);
        if (res.status) {
          this.actionTypes = res.response;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus"),
          {
            status: true,
            icon: "error",
            title: err.message,
          };
      }
    },
  },

  watch: {
    currentPage: {
      handler: function (v) {
        if (v != "" && v != null) {
          this.fetchData();
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  created() {
    if (
      this.$route.name == "add-wallet-offers" ||
      this.$route.name == "edit-wallet-offers"
    ) {
      this.fetchActionTypes();
      if (this.$route.name == "edit-wallet-offers") {
        this.fetchoffer(this.$route.params.id);
      }
    } else {
      this.fetchData();
    }
  },
};
