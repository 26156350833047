<template lang="">
  <Layout>
    <template v-if="this.$route.name == 'add-wallet-offers'">
      <PageHeader :title="title1" :items="items"></PageHeader>
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items"></PageHeader>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="email" class="row">
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label style="">Title <span style="color: red">*</span></label>
                <b-form-input
                  id="input-1"
                  placeholder="Enter Title"
                  v-model="form.title"
                  :class="{
                    'is-invalid': submitted && $v.form.title.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.title.required"
                  class="invalid-feedback"
                >
                  Title is required.
                </div>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label style=""
                  >Action Type <span style="color: red">*</span></label
                >
                <multiselect
                  id="item"
                  :options="actionTypes"
                  v-model="form.action_type"
                  :multiple="false"
                  track-by="value"
                  label="value"
                  placeholder="Type here to search"
                  :class="{
                    'is-invalid': submitted && $v.form.action_type.$error,
                  }"
                >
                  <span slot="noOptions"> Type here to search </span>
                </multiselect>
                <div
                  v-if="submitted && !$v.form.action_type.required"
                  class="invalid-feedback"
                >
                  Action Type is required.
                </div>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label style="">Amount <span style="color: red">*</span></label>
                <b-form-input
                  id="input-1"
                  placeholder="Enter Amount"
                  type="number"
                  v-model="form.amount"
                  :class="{
                    'is-invalid': submitted && $v.form.amount.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.amount.required"
                  class="invalid-feedback"
                >
                  Amount is required.
                </div>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label style=""
                  >Button Text <span style="color: red">*</span></label
                >
                <b-form-input
                  id="input-1"
                  placeholder="Enter Button Text"
                  v-model="form.btn_txt"
                  :class="{
                    'is-invalid': submitted && $v.form.btn_txt.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.btn_txt.required"
                  class="invalid-feedback"
                >
                  Button Text is required.
                </div>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-12"
              >
                <label>Description <span style="color: red">*</span></label>
                <ckeditor
                  v-model="form.description"
                  :editor="editor"
                  :class="{
                    'is-invalid': submitted && $v.form.description.$error,
                  }"
                ></ckeditor>
                <div
                  v-if="submitted && !$v.form.description.required"
                  class="invalid-feedback"
                >
                  Description is required.
                </div>
              </b-form-group>

              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-12"
              >
                <b-form-checkbox v-model="form.is_active"
                  >is active</b-form-checkbox
                >
              </b-form-group>

              <div class="col-12">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-2"
                  @click.prevent="submitData"
                  ><span v-if="this.$route.name == 'add-wallet-offers'"
                    >Save Data</span
                  >
                  <span v-else>Update Data</span>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import walletOffersMixin from "../../../mixins/ModuleJs/wallet-offers";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  mixins: [MixinRequest, walletOffersMixin],
  data() {
    return {
      editor: ClassicEditor,
      title1: "ADD Wallet Offer",
      title2: "EDIT Wallet Offer",
      items: [
        {
          text: "Back",
          href: "/wallet-offers",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  components: {
    ckeditor: CKEditor.component,
    Layout,
    PageHeader,
    Multiselect,
  },
  validations: {
    form: {
      title: { required },
      description: { required },
      action_type: { required },
      amount: { required },
      btn_txt: { required },
    },
  },
};
</script>
